<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <h1 class="list-title">Search</h1>

        <!--s: Launch -->
        <div class="search-body">

            <!--s: table-->
            <div class="launch-table">
                <div class="launch-type">
                    <a href="javascript:" :class="searchParams.cid1 === item.id?'active':''" :key="key" v-for="(item,key) in classification" @click="onTypeSelect(item.id)">{{ item.name }}</a>
                </div>
                <div class="launch-top">
                    <div class="launch-tab">
                        <a href="javascript:" :class="searchParams.searchValue === search.value?'active':''" :key="key" v-for="(search,key) in searchList" @click="onSelectTab(search.value)">{{ search.label }}</a>
                    </div>
                    <a href="javascript:" class="launch-tab-add" @click="onAddContentList">
                        <Icon custom="launch-add"></Icon>
                    </a>
                </div>
                <div class="launch-content">
                    <Table max-height="350" :loading="searchLoading"  :columns="searchColumns" :data="searchArray" @on-select-all="onSelectContentAll" @on-select-all-cancel="onSelectContentAllCancel" @on-select="onSelectContent" @on-select-cancel="onSelectContentCancel">
                        <template slot="startTime" slot-scope="{ row }">
                            {{ formatUnit(row.startTime) }}
                        </template>
                        <template slot="endTime" slot-scope="{ row }">
                            {{ formatUnit(row.endTime) }}
                        </template>
                        <template slot-scope="{ row }" slot="status">
                            <Dropdown @on-click="onSelectItem($event,row.id)">
                                <a href="javascript:void(0)" class="select-item">
                                    {{ row.status === 1?'Random':'' }}
                                    {{ row.status === 2?'On':'' }}
                                    {{ row.status === 3?'Pause':'' }}
                                    <Icon type="ios-arrow-down"></Icon>
                                </a>
                                <DropdownMenu slot="list">
                                    <DropdownItem name="1" :selected="row.status === 1">Random</DropdownItem>
                                    <DropdownItem name="2" :selected="row.status === 2">On</DropdownItem>
                                    <DropdownItem name="3" :selected="row.status === 3">Pause</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </template>
                        <template slot="btn" slot-scope="{ row }">
                            <a href="javascript:" class="btn" @click="onEditContent(row.id)">Edit</a>
                        </template>
                    </Table>
                    <div class="launch-btn">
                        <div class="box">
                            <div class="pint" v-if="searchStatus">
                                <span class="title">Sure delete?</span>
                                <Button style="width:71px" :loading="btnStatus" @click="onContentDeleteConfirm">Yes</Button>
                                <Button @click="searchStatus = false">Cancel</Button>
                            </div>
                            <Button @click="onContentDelete">Delete</Button>
                        </div>
                    </div>
                  </div>
            </div>
            <!--e: table-->

        </div>
        <!--e: Launch -->

    </div>
</template>
<script>
import SearchList from './list'
export default SearchList
</script>
<style lang="less">
@import "list";
</style>
