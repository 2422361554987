import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 获取Search数据
 * @param params
 * @returns {Promise<*>}
 */
export const getSearchListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/market/search/get/type?cid1='+params.cid1+'&type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * delete 删除Search数据
 * @param params
 * @returns {Promise<*>}
 */
export const delSearchListService = async (params) => {
    try {
        const { data } = await request.delete(evnUrl.concat('/api/market/search?ids='+params.ids))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 获取Search 详情 数据
 * @param params
 * @returns {Promise<*>}
 */
export const getSearchInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/market/search?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 修改列表状态
 * @param params
 * @returns {Promise<*>}
 */
export const getSearchEditStatusService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/market/search/update/status?id='+params.id+'&status='+params.status))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 编辑图文数据
 * @param params
 * @returns {Promise<*>}
 */
export const editSearchImageTextService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/market/search'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
