/**
 * 搜索数据集
 * @type {({})[]}
 */
export const searchList = [
    {
        value:1,
        label:'Homepage',
    },
    {
        value:2,
        label:'Recommend',
    },
    {
        value:3,
        label:'Keywords',
    },
    {
        value:4,
        label:'Brands',
    },
    {
        value:5,
        label:'Featured',
    },
]

/**
 * 搜索头数据
 * @type {*[]}
 */
export const searchColumns = [
    {
        title: 'Selection',
        width:140,
        key: 'coverTitle'
    },
    {
        title: 'Start',
        width:80,
        slot:'startTime',
    },
    {
        title: 'End',
        width:80,
        slot:'endTime',
    },
    {
        title: 'Status',
        slot:'status',
    },
    {
        type: 'selection',
        width:60,
        align: 'center'
    },
    {
        title:' ',
        width:120,
        slot:'btn',
    }
]
